import * as React from "react"
import { createRoot } from "react-dom/client"
import { ApolloClient, ApolloProvider } from "@apollo/client"
import { SessionContext } from "@digits-shared/components/Contexts/SessionContext"
import { StylesContextProvider } from "@digits-shared/components/Contexts/StylesContextProvider"
import { DigitsBrowserRouter } from "@digits-shared/components/Router/DigitsRouter"
import { Theme } from "@digits-shared/themes"
// #if process.env.NODE_ENV === 'test'
// Only import our mock client when webpack builds in test env
// import useMockClient from "test/admin/support/graphql/mockClient"
// #endif
import useSetupApolloClient from "src/shared/initializers/apollo/useSetupApolloClient"
import { GlobalStyles } from "src/shared/initializers/global"
import { AdminClient } from "src/admin/components/Client"
import routes from "src/admin/routes"
import AdminSession from "src/admin/session"
import "digits-shared/static/css/fonts.css"

const session = new AdminSession()

const Entry: React.FC = () => {
  const client = useSetupApolloClient(session, {
    defaultFetchPolicy: "network-only",
    neverBatch: true,
    withWS: true,
  })

  return <Main apolloClient={client} />
}

// const TestEntry: React.FC = () => <Main apolloClient={useMockClient()} />

const Main: React.FC<{
  apolloClient: ApolloClient<{}>
}> = ({ apolloClient }) => (
  <ApolloProvider client={apolloClient}>
    <SessionContext.Provider value={session}>
      <StylesContextProvider mode={Theme.Dark}>
        <GlobalStyles />
        <DigitsBrowserRouter routes={routes}>
          <AdminClient />
        </DigitsBrowserRouter>
      </StylesContextProvider>
    </SessionContext.Provider>
  </ApolloProvider>
)

const container = document.getElementById("main")
const root = createRoot(container || document.createDocumentFragment())

// When webpack builds in test env, use our mock client for the app
root.render(process.env.NODE_ENV === "test" ? <Entry /> : <Entry />)
