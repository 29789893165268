import * as React from "react"
import BreakingVersionDetector from "@digits-shared/components/Error/BreakingVersionDetector"
import useSession from "@digits-shared/hooks/useSession"
import Session from "@digits-shared/session/Session"
import FrontendSession from "src/frontend/session"
import BackgroundTask from "src/shared/components/Background/BackgroundTask"

/*
 COMPONENTS
*/

const ClientVersionTracker: React.FC = () => {
  const session = useSession<FrontendSession>()
  const { current: breakingVersionDetector } = React.useRef(
    new BreakingVersionDetector(session.appRefreshRequired)
  )

  const reloadPage = React.useCallback(() => window.location.reload(), [])

  React.useEffect(() => {
    session.on(Session.PAGE_REFRESH_REQUIRED_EVENT_NAME, reloadPage)
    return () => {
      session.off(Session.PAGE_REFRESH_REQUIRED_EVENT_NAME, reloadPage)
    }
  }, [reloadPage, session])

  // Check every 5 minutes
  return (
    <BackgroundTask frequency={1000 * 60 * 5} task={breakingVersionDetector.checkBreakingVersion} />
  )
}
export default ClientVersionTracker
