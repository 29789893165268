import { svgIconStyles, svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import colorHelper from "@digits-shared/helpers/colorHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"

export type ButtonVariant =
  | "primary"
  | "secondary-dark"
  | "secondary-light"
  | "secondary-white"
  | "ghost-dark"
  | "ghost-light"
  | "ghost-error"
  | "error"

export type ButtonSize = "small" | "medium" | "default"

export interface ButtonProps {
  size?: ButtonSize
  width?: number
  $variant?: ButtonVariant
  $fullWidth?: boolean
  disabled?: boolean
  $active?: boolean
  $circle?: boolean
  $solidIcon?: boolean
}

export const TERTIARY_VARIANTS = ["ghost-dark", "ghost-light", "ghost-error"]

export const DigitsButtonStyle = css<ButtonProps>`
  ${({ $variant, $active, $solidIcon }) => {
    switch ($variant) {
      case "secondary-dark":
        return css`
          color: ${colors.secondary};
          background: ${colors.translucentSecondary05};

          svg {
            ${$solidIcon ? svgIconStyles(colors.secondary) : svgPathStyles(colors.secondary, 1.5)};
          }

          &[disabled] {
            color: ${colors.secondary60};

            svg {
              ${$solidIcon
                ? svgIconStyles(colors.secondary60)
                : svgPathStyles(colors.secondary60, 1.5)};
            }
          }

          ${$active
            ? css`
                background: ${colors.translucentSecondary20};
              `
            : css`
                &:hover:not([disabled]) {
                  background: ${colors.translucentSecondary10};
                }
              `};
        `
      case "secondary-light":
        return css`
          color: ${colors.secondary};
          background: ${colors.translucentWhite40};

          svg {
            ${$solidIcon ? svgIconStyles(colors.secondary) : svgPathStyles(colors.secondary, 1.5)};
          }

          &[disabled] {
            color: ${colors.translucentSecondary60};
            background: ${colors.translucentWhite20};

            svg {
              ${$solidIcon
                ? svgIconStyles(colors.secondary60)
                : svgPathStyles(colors.secondary60, 1.5)};
            }
          }

          ${$active
            ? css`
                background: ${colors.translucentWhite80};
              `
            : css`
                &:hover:not([disabled]) {
                  background: ${colors.secondary05};
                }
              `};
        `
      case "ghost-dark":
        return css`
          color: ${colors.secondary};
          background: ${colors.transparent};

          svg {
            ${$solidIcon ? svgIconStyles(colors.secondary) : svgPathStyles(colors.secondary, 1.5)};
          }

          &[disabled] {
            color: ${colors.secondary60};

            svg {
              ${$solidIcon
                ? svgIconStyles(colors.secondary60)
                : svgPathStyles(colors.secondary60, 1.5)};
            }
          }

          ${$active
            ? css`
                background: ${colors.translucentSecondary20};
              `
            : css`
                &:hover:not([disabled]) {
                  background: ${colors.translucentSecondary05};
                }
              `};
        `
      case "ghost-light":
        return css`
          color: ${colors.white};
          background: ${colors.transparent};

          svg {
            ${$solidIcon ? svgIconStyles(colors.white) : svgPathStyles(colors.white, 1.5)};
          }

          &[disabled] {
            color: ${colors.translucentWhite60};

            svg {
              ${$solidIcon
                ? svgIconStyles(colors.translucentWhite60)
                : svgPathStyles(colors.translucentWhite60, 1.5)};
            }
          }

          ${$active
            ? css`
                background: ${colors.translucentWhite20};
              `
            : css`
                &:hover:not([disabled]) {
                  background: ${colors.translucentWhite10};
                }
              `};
        `
      case "ghost-error":
        return css`
          color: ${colors.orange};
          background: ${colors.transparent};

          svg {
            ${$solidIcon ? svgIconStyles(colors.error) : svgPathStyles(colors.error, 1.5)};
          }

          &[disabled] {
            color: ${colors.translucentOrange70};

            svg {
              ${$solidIcon
                ? svgIconStyles(colors.translucentOrange70)
                : svgPathStyles(colors.translucentOrange70, 1.5)};
            }
          }

          ${$active
            ? css`
                background: ${colors.translucentOrange10};
              `
            : css`
                &:hover:not([disabled]) {
                  background: ${colors.translucentOrange05};
                }
              `};
        `
      case "error":
        return css`
          color: ${colors.white};
          background: ${colors.orange};

          svg {
            ${$solidIcon ? svgIconStyles(colors.white) : svgPathStyles(colors.white, 1.5)};
          }

          &[disabled] {
            background: ${colors.translucentOrange70};

            svg {
              ${$solidIcon
                ? svgIconStyles(colors.translucentWhite70)
                : svgPathStyles(colors.translucentWhite70, 1.5)};
            }
          }

          ${$active
            ? css`
                background: ${colors.errorDark};
              `
            : css`
                &:hover:not([disabled]) {
                  background: ${colors.errorDark};
                }
              `};
        `

      case "secondary-white":
        return css`
          color: ${colors.white};
          background: ${colors.translucentWhite10};

          svg {
            ${$solidIcon ? svgIconStyles(colors.white) : svgPathStyles(colors.white, 1.5)};
          }

          &[disabled] {
            color: ${colors.translucentWhite60};

            svg {
              ${$solidIcon
                ? svgIconStyles(colors.translucentWhite60)
                : svgPathStyles(colors.translucentWhite60, 1.5)};
            }
          }

          ${$active
            ? css`
                background: ${colors.translucentWhite30};
              `
            : css`
                &:hover:not([disabled]) {
                  background: ${colors.translucentWhite20};
                }
              `};
        `

      case "primary":
      default:
        return css`
          color: ${colors.secondaryDark};
          background: ${colors.accentGreen};

          svg {
            ${$solidIcon
              ? svgIconStyles(colors.secondaryDark)
              : svgPathStyles(colors.secondaryDark, 1.5)};
          }

          &[disabled] {
            color: ${colors.secondary70};
            background: ${colorHelper.hexToRgba(colors.accentGreen, 0.4)};

            svg {
              ${$solidIcon
                ? svgIconStyles(colors.secondary70)
                : svgPathStyles(colors.secondary70, 1.5)};
            }
          }

          ${$active
            ? css`
                background: #4bcfad;
              `
            : css`
                &:hover:not([disabled]) {
                  background: #75ffdc;
                }
              `};
        `
    }
  }};

  padding: 0;
  border: none;
  outline-offset: 0;

  &,
  &:focus,
  &:active {
    outline: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: ${fonts.weight.heavy} !important;
  white-space: nowrap;

  width: ${({ width }) => (width ? `${width}px;` : "fit-content")};
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}

  ${({ size, $circle, $variant }) => {
    switch (size) {
      case "small":
        return css`
          ${fonts.scale.detail};
          padding: 0 16px;
          height: 24px;
          gap: 4px;

          svg {
            width: 16px;
            height: 16px;
            margin-left: -4px;
          }

          ${$circle &&
          css`
            width: 24px;
          `};

          ${TERTIARY_VARIANTS.includes($variant || "") &&
          css`
            padding: 0 12px;
          `};
        `
      case "medium":
        return css`
          ${fonts.scale.body};
          padding: 0 16px;
          height: 32px;
          gap: 4px;

          svg {
            width: 20px;
            height: 20px;
            margin-left: -6px;
          }

          ${$circle &&
          css`
            width: 32px;
          `};

          ${TERTIARY_VARIANTS.includes($variant || "") &&
          css`
            padding: 0 12px;
          `};
        `
      case "default":
      default:
        return css`
          ${fonts.scale.body};
          padding: 0 24px;
          height: 40px;
          gap: 6px;

          ${$circle &&
          css`
            width: 40px;
          `};

          svg {
            width: 20px;
            height: 20px;
            margin-left: -8px;
          }

          ${TERTIARY_VARIANTS.includes($variant || "") &&
          css`
            padding: 0 16px;
          `};
        `
    }
  }};

  ${({ $circle }) =>
    $circle
      ? css`
          border-radius: 50%;
          padding: 0;

          svg {
            margin-left: unset;
          }
        `
      : css`
          border-radius: 32px;
        `};

  &:not([disabled]) {
    cursor: pointer;
  }

  &[disabled] {
    pointer-events: none;
  }
`

/**
 * Digits Button
 *
 * Defaults to a primary buttons with default size and rounded shape.
 * Buttons can have a custom width, but height should not be adjusted.
 */
export const DigitsButton = styled.button<ButtonProps>`
  ${DigitsButtonStyle};
`
export const ONBOARD_BUTTON_MAX_WIDTH = "350px"

export const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`
