import * as React from "react"
import useConstant from "@digits-shared/hooks/useConstant"
import { Theme } from "@digits-shared/themes"
import isPropValid from "@emotion/is-prop-valid"
import { ShouldForwardProp, StyleSheetManager, ThemeContext } from "styled-components"

export const StylesContextProvider: React.FC<React.PropsWithChildren<{ mode: Theme }>> = ({
  mode,
  children,
}) => {
  // Allow forwarding props to React if they are valid (isPropValid)
  // or if the element-to-be-created by styled-components is not a string.
  // examples:
  //    styled.div === "string"
  //    styled(Foo) === "function"
  //    css`` == "object"
  const shouldForwardProp = React.useCallback<ShouldForwardProp<"web">>(
    (prop, elementToBeCreated) => typeof elementToBeCreated !== "string" || isPropValid(prop),
    []
  )

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp} enableVendorPrefixes>
      <ThemeContext.Provider value={useConstant({ mode })}>{children}</ThemeContext.Provider>
    </StyleSheetManager>
  )
}
