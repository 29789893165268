import {
  defaultDataIdFromObject,
  InMemoryCache,
  PossibleTypesMap,
  TypePolicies,
} from "@apollo/client/cache"
import {
  dataSource,
  employee,
  entityParty,
  layout,
  objectAcl,
  objectMapping,
  queries,
  reportPackage,
  reportPackageDocument,
  searchResult,
  team,
  teamMember,
  threads,
  transaction,
  transactionParty,
  transactionsWithSummaries,
  vaultFields,
  workItem,
} from "src/shared/initializers/typePolicies"

const typePolicies: TypePolicies = {
  Query: {
    fields: {
      ...queries,
    },
  },
  ...objectAcl,
  ...teamMember,
  ...team,
  ...employee,
  ...transaction,
  ...transactionParty,
  ...transactionsWithSummaries,
  ...threads,
  ...searchResult,
  ...entityParty,
  ...reportPackage,
  ...reportPackageDocument,
  ...layout,
  ...workItem,
  ...dataSource,
  ...vaultFields,
  ...objectMapping,
}

const possibleTypes: PossibleTypesMap = {
  MetricTerm: ["MetricCategoriesOperand", "MetricSummaryTypeOperand", "MetricOperation"],
}

export const cache = new InMemoryCache({
  typePolicies,
  possibleTypes,
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      // Turn off caching for specific types
      // Category is included here to address a hierarchy rendering issue.
      case "Category":
      case "CategoryTransactionSummary":
      case "QuickBooksIntegration":
      case "User":
        return undefined
      default:
        return defaultDataIdFromObject(object)
    }
  },
})
