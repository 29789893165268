import { css } from "styled-components"

export const DIGITS_BACKGROUND = css`
  background:
    radial-gradient(ellipse at 38% 45%, rgba(222, 227, 223, 1) 0%, rgba(220, 226, 223, 0) 90%),
    linear-gradient(135deg, #a5cbe7, #9db9f0) no-repeat;
  background-size: cover;
`

export const NAVIGATION_BACKGROUND = css`
  background-color: #4471c5;
  background: radial-gradient(
    ellipse at 10% 90%,
    #479fb8 0%,
    #4695b9 10%,
    #4695b9 25%,
    #4471c5 59%,
    #4471c5 70%,
    #4471c5 91%,
    #4471c5 100%
  );
  background-size: cover;
`
