export default {
  /**
   * @return {boolean} a boolean value if the package was built for production.
   */
  isProduction() {
    return process.env.NODE_ENV === "production" && process.env.STAGING !== "true"
  },

  /**
   * @return {boolean} a boolean value if the package was built for staging.
   */
  isStaging() {
    return (
      process.env.NODE_ENV === "staging" ||
      (process.env.NODE_ENV === "production" && process.env.STAGING === "true")
    )
  },

  /**
   * @return {boolean} a boolean value if the package was built for development.
   */
  isDevelopment() {
    return process.env.NODE_ENV === "development"
  },

  /**
   * @return {boolean} a boolean value if the package was built for test.
   */
  isTest() {
    return process.env.NODE_ENV === "test"
  },

  isMocks() {
    return process.env.NODE_ENV === "mocks"
  },
}
