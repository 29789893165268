import { onError } from "@apollo/client/link/error"
import BreakingVersionDetector from "@digits-shared/components/Error/BreakingVersionDetector"
import { GRPCErrorCode } from "@digits-shared/grpc/codes"
import Session from "@digits-shared/session/Session"

const tracker = process.env.GRAPHQL_ENDPOINT?.split("/").pop() === "graphql" ? TrackJS : undefined

export default (session: Session) => {
  const breakingVersionDetector = new BreakingVersionDetector(session.appRefreshRequired)

  return onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(async (error) => {
        const { extensions } = error
        const code = extensions && extensions.code

        switch (code) {
          case GRPCErrorCode.Unauthenticated:
            tracker?.console.info("User is unauthenticated, logging out of session")
            await session.clear({
              code,
              message: "Your session has expired. Please log back in.",
            })
            break

          default: {
            // Check for breaking version if we've hit an error. If a breaking
            // version has changed then an app reload will be triggered.
            breakingVersionDetector.checkBreakingVersion()
          }
        }
      })
    }
  })
}
