import { TypePolicies } from "@apollo/client"

export const employee: TypePolicies = {
  Employee: {
    keyFields: ["id"],
  },
  AdminEmployee: {
    keyFields: ["employee", ["id"]],
  },
}
