import { ApolloLink, Observable } from "@apollo/client"
import Session from "@digits-shared/session/Session"

export default (session: Session) =>
  new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        forward(operation)
          .forEach((response) => {
            const context = operation.getContext()
            const {
              response: { headers },
            } = context

            // If we provided this header, it informs us we are in need of an immediate token refresh
            // so make a blocking request to get a new token.
            if (headers && headers.has("x-bearer-updated") && session.hasUserData) {
              session.blockingBearerRefresh(context.skipNotifyTokenUpdate)
              session
                .bearer()
                .then(() => {
                  observer.next(response)
                  observer.complete()
                  session.mutationVersionUpdate()
                })
                .catch((error) => {
                  session.bearerFetchError(error)
                  observer.error(error)
                })

              return
            }

            // Otherwise just forward to the next link in the chain
            observer.next(response)
            observer.complete()
          })
          .catch((error) => {
            session.bearerFetchError(error)
            observer.error(error)
          })
      })
  )
