import * as React from "react"
import { useTokenRefreshMutation } from "@digits-graphql/frontend/graphql-public"
import useSessionTokenRefresh from "@digits-shared/hooks/useSessionTokenRefresh"

const BearerRefresh: React.FC = () => {
  const [tokenRefreshMutation] = useTokenRefreshMutation()
  useSessionTokenRefresh(tokenRefreshMutation)
  return null
}

export default BearerRefresh
