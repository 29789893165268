import { TypePolicies } from "@apollo/client"

export const teamMember: TypePolicies = {
  TeamMember: {
    keyFields: ["user", ["id"]],
  },
}

export const team = {
  Team: {
    fields: {
      members: {
        merge: false,
      },
    },
  },
}
