import { JWTUser } from "@digits-shared/session/jwt/jwtUser"
import moment from "moment"

export interface SessionUserAttributes {
  id: string
  givenName: string
  familyName: string
  emailAddress: string
  avatarUrl: string
  termsAcceptedAt?: Date
}

/**
 * Simple wrapper for user stored on JWT
 *
 * Provides convenience methods, better naming
 * than what is found on the intentionally abbreviated JWT,
 * and clean types.
 */
export default class SessionUser {
  attributes: SessionUserAttributes

  constructor(rawSessionUser?: JWTUser) {
    if (!rawSessionUser) {
      this.attributes = {} as SessionUserAttributes
      return
    }

    const {
      id,
      given: givenName,
      family: familyName,
      email: emailAddress,
      pic: avatarUrl,
      terms,
    } = rawSessionUser

    const termsAcceptedAt = terms ? moment(terms, "YYYY-MM-DD").toDate() : undefined
    this.attributes = {
      id,
      givenName,
      familyName,
      emailAddress,
      avatarUrl,
      termsAcceptedAt,
    }
  }

  get id() {
    return this.attributes.id
  }

  get fullName() {
    const { givenName, familyName, emailAddress } = this.attributes
    const details = []
    if (givenName) details.push(givenName)
    if (familyName) {
      details.push(familyName)
    }

    if (!details.length) {
      details.push(emailAddress)
    }

    return details.join(" ")
  }

  get givenName() {
    return this.attributes.givenName
  }

  get familyName() {
    return this.attributes.familyName
  }

  get emailAddress() {
    return this.attributes.emailAddress
  }

  get avatarUrl() {
    return this.attributes.avatarUrl
  }

  get termsAcceptedAt() {
    return this.attributes.termsAcceptedAt
  }
}
