import { Deflater } from "@stardazed/zlib"

const comContext = Symbol("comContext")
const comTransform = Symbol("comTransform")

/**
 * Ponyfill for browsers or node.js when CompressionStream is not supported natively.
 */

export class CompressionStream {
  private [comContext]: Deflater
  private [comTransform]: TransformStream<BufferSource, Uint8Array | undefined>

  constructor(format: string) {
    if (format !== "deflate" && format !== "gzip") {
      throw new TypeError("format must be one of `deflate`, `gzip`")
    }

    this[comContext] = new Deflater({ format })
    this[comTransform] = new TransformStream(new CompressionTransformer(this[comContext]))
  }

  get readable() {
    return this[comTransform].readable
  }

  get writable() {
    return this[comTransform].writable
  }
}

class CompressionTransformer implements Transformer<BufferSource, Uint8Array> {
  private deflater: Deflater

  constructor(deflater: Deflater) {
    this.deflater = deflater
  }

  transform(chunk: BufferSource, controller: TransformStreamDefaultController<Uint8Array>) {
    if (!(chunk instanceof ArrayBuffer || ArrayBuffer.isView(chunk))) {
      throw new TypeError("Input data must be a BufferSource")
    }
    const buffers = this.deflater.append(chunk)
    buffers.forEach((buf) => controller.enqueue(buf))
  }

  flush(controller: TransformStreamDefaultController<Uint8Array>) {
    const buffers = this.deflater.finish()
    buffers.forEach((buf) => controller.enqueue(buf))
  }
}
