import { isArray } from "@apollo/client/cache/inmemory/helpers"
import { onError } from "@apollo/client/link/error"
import BreakingVersionDetector from "@digits-shared/components/Error/BreakingVersionDetector"
import Session from "@digits-shared/session/Session"
import { GraphQLError } from "graphql"

// Used for inspecting errors returned from WS GraphQL requests. Unlike HTTP GraphQL, WS will
// attempt to handle network errors along with reported GraphQL errors.
export default (session: Session) => {
  const breakingVersionDetector = new BreakingVersionDetector(session.appRefreshRequired)

  return onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors || networkError) {
      const errors = (graphQLErrors || []).concat(
        networkError ? (isArray(networkError) ? networkError : [networkError as GraphQLError]) : []
      )

      errors.forEach((error) => {
        const { extensions } = error
        const code = extensions?.code

        switch (code) {
          default: {
            // Check for breaking version if we've hit an error. If a breaking
            // version has changed then an app reload will be triggered.
            breakingVersionDetector.checkBreakingVersion()
          }
        }
      })
    }
  })
}
