import * as React from "react"
import { match as Match, useHistory, useLocation, useRouteMatch } from "react-router-dom"
import { DigitsLocation } from "@digits-shared/components/Router/DigitsLocation"
import { DigitsHistory } from "@digits-shared/components/Router/History"
import useSearchQuery from "@digits-shared/hooks/useSearchQuery"
import { Location, Path } from "history"

export interface DigitsUseRouter {
  push: (path: Path, state?: Location) => void
  replace: (path: Path, state?: Location) => void
  pathname: string
  params: Record<string, string>
  location: DigitsLocation
  previousLocation?: DigitsLocation
  history: DigitsHistory
  match: Match<Record<string, string>>
  search: URLSearchParams
}

/**
 * Returns a custom router object
 */
export default function useRouter() {
  const params = useMemoParams<Record<string, string>>()
  const match = useMemoRouteMatch<Record<string, string>>()
  const search = useSearchQuery()
  const history = useHistory() as DigitsHistory
  const { location, previousLocation } = history

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return React.useMemo<DigitsUseRouter>(
    () => ({
      // For convenience add push(), replace(), pathname at top level
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,

      // Include params, match, location, history objects so we have
      // access to extra React Router functionality if needed.
      params,
      match,
      search,
      location,
      previousLocation,
      history,
    }),
    [history, location, params, match, search, previousLocation]
  )
}

// WORKAROUND for bug in react router
// https://github.com/ReactTraining/react-router/issues/7059
// useRouteMatch returns a new object on every render, `location.key` is very stable so using that
// to memoize the match record and workaround this bug
function useMemoRouteMatch<Params extends { [K in keyof Params]?: string } = {}>(): Match<Params> {
  const { location } = useHistory() as DigitsHistory
  // Less accurate location due to our usage of layered routers in webapp. However leveraging it
  // allows up to properly bust the match catch if it changes.
  const loc = useLocation() as DigitsLocation
  const match = useRouteMatch<Params>()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => match, [location.key, loc.key])
}

// WORKAROUND for bug in react router
// https://github.com/ReactTraining/react-router/issues/7059
function useMemoParams<Params extends { [K in keyof Params]?: string } = {}>(): Params {
  const match = useMemoRouteMatch<Params>()
  return React.useMemo(() => match?.params || {}, [match])
}
