import { ApolloLink } from "@apollo/client"

export default () =>
  new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        ...operation.getContext().headers,
        // this process.env.BREAKING_VERSION variable is built into
        // the app at compile time via webpack, and is intended to
        // remain static for the lifetime of this app version.
        "x-client-version": process.env.BREAKING_VERSION,
      },
    })

    return forward(operation) || null
  })
