import React from "react"

export const THEME_PREFERENCE_KEY = "theme"

export enum Theme {
  Light = "light",
  Dark = "dark",

  // Print is optional in all helpers and functions.
  // When a value is not provided, it will fall back to "light"
  Print = "print",
}

export interface DigitsTheme {
  mode: Theme
  setMode?: React.Dispatch<Theme>
}

export interface DeprecatedThemeProps {
  themeName?: string
}
