import { Inflater } from "@stardazed/zlib"

/**
 * Polyfill for browsers or node.js when DecompressionStream is not supported natively.
 */

const decContext = Symbol("decContext")
const decTransform = Symbol("decTransform")

export class DecompressionStream {
  private [decContext]: Inflater
  private [decTransform]: TransformStream<BufferSource, Uint8Array | undefined>

  constructor(format: string) {
    if (format !== "deflate" && format !== "gzip") {
      throw new TypeError("format must be one of `deflate`, `gzip`")
    }

    this[decContext] = new Inflater()
    this[decTransform] = new TransformStream(new DecompressionTransformer(this[decContext]))
  }

  get readable() {
    return this[decTransform].readable
  }

  get writable() {
    return this[decTransform].writable
  }
}

class DecompressionTransformer implements Transformer<BufferSource, Uint8Array> {
  private inflater: Inflater

  constructor(inflater: Inflater) {
    this.inflater = inflater
  }

  transform(chunk: BufferSource, controller: TransformStreamDefaultController<Uint8Array>) {
    if (!(chunk instanceof ArrayBuffer || ArrayBuffer.isView(chunk))) {
      throw new TypeError("Input data must be a BufferSource")
    }
    const buffers = this.inflater.append(chunk)
    buffers.forEach((buf) => controller.enqueue(buf))
  }

  flush(_controller: TransformStreamDefaultController<Uint8Array>) {
    const result = this.inflater.finish()
    if (!result.success) {
      if (!result.complete) {
        throw new Error("Unexpected EOF during decompression")
      }
      if (result.checksum === "mismatch") {
        throw new Error("Data integrity check failed")
      }
      if (result.fileSize === "mismatch") {
        throw new Error("Data size check failed")
      }
      throw new Error("Decompression error")
    }
  }
}
