import { StorageFacade } from "@digits-shared/helpers/storage/storage"
import { JWTObjectSession } from "@digits-shared/session/jwt/jwtObject"
import { jwtParse } from "@digits-shared/session/jwt/jwtParser"
import { ObjectToken } from "@digits-shared/session/ObjectToken"
import { TrackJS } from "trackjs"

export class ObjectTokens {
  private storage: StorageFacade

  constructor(storage: StorageFacade) {
    this.storage = storage
  }

  async saveToken(encodedSignedToken: string) {
    const { decodedUnsignedJWT } = await jwtParse<JWTObjectSession>(encodedSignedToken)

    const objectToken = new ObjectToken(decodedUnsignedJWT?.obj)

    const document = objectToken.resources?.document ?? objectToken.resources?.["secure-file"]
    const documentId = document ? Object.keys(document).pop() : undefined

    if (!documentId) {
      TrackJS?.track("Object token is missing a document")
      return
    }

    this.storage.session.setItem(documentId, encodedSignedToken)
    return documentId
  }

  getToken(documentId: string) {
    return Promise.resolve(this.storage.session.getItem(documentId))
  }

  removeToken(documentId: string) {
    return this.storage.session.removeItem(documentId)
  }
}
