import { DeferredPromise } from "@digits-shared/helpers/promises/DeferredPromise"
import Session from "@digits-shared/session/Session"
import { GraphQLError } from "graphql"

export default class BearerRefreshManager {
  static TOKEN_BLOCKING_STARTED_EVENT_NAME = "bearer-refresh:token:blocking-started"

  static TOKEN_NON_BLOCKING_STARTED_EVENT_NAME = "bearer-refresh:token:nonblocking-started"

  static TOKEN_REFRESHED_EVENT_NAME = "bearer-refresh:token:refreshed"

  static FETCH_ERROR_EVENT_NAME = "bearer-refresh:fetch:error"

  private fetch: DeferredPromise<void>

  constructor(session: Session) {
    session.on(BearerRefreshManager.TOKEN_BLOCKING_STARTED_EVENT_NAME, this.fetchStarted)
    session.on(BearerRefreshManager.TOKEN_REFRESHED_EVENT_NAME, this.fetchResolved)
    session.on(BearerRefreshManager.FETCH_ERROR_EVENT_NAME, this.fetchRejected)
    setInterval(session.nonBlockBearerRefreshIfNeeded, 1000 * 30)
  }

  fetchStarted = () => {
    if (this.fetch?.isPending) return

    this.fetch = new DeferredPromise()
  }

  fetchResolved = () => {
    // Because we are using this fetch as a semaphore to indicate
    // if an underlying token refresh has occurred, we want to
    // clean up rejected state if a success occurs after a previous
    // failure.
    if (!this.fetch || this.fetch.isRejected) {
      this.fetch = new DeferredPromise<void>()
    }

    this.fetch?.resolve()
  }

  fetchRejected = (error: GraphQLError) => {
    this.fetch?.reject(error)
  }

  get promise() {
    return this.fetch.promise
  }
}
