// This shasum of an empty string is intended as an additional
// protection measure against refresh loops caused by bugs in the
// process of determining BREAKING_VERSION in CI.
const SHASUM_EMPTY_STRING = "da39a3ee5e6b4b0d3255bfef95601890afd80709"

/**
 * Used to detect a breaking version of our API. If our API schema changes and the webapp has not
 * reloaded yet, then we risk making requests with bad fields. Doing so will trigger a request error
 * and prevent the dashboard from functioning correctly. This class is responsible for hitting an
 * endpoint which provides a break version code. If the breaking version changes over the lifetime
 * of the webapp, then the user will be prompted that they need to reload to prevent errors.
 */
export default class BreakingVersionDetector {
  // This process.env.BREAKING_VERSION variable is built into
  // the app at compile time via webpack, and is intended to
  // remain static for the lifetime of this app version.
  static BREAKING_VERSION = process.env.BREAKING_VERSION

  checkingForBreakingVersion: Promise<void | Response> | undefined

  onPageRefreshRequired: () => void

  constructor(onPageRefreshRequired: () => void) {
    this.onPageRefreshRequired = onPageRefreshRequired
  }

  checkBreakingVersion = () => {
    if (this.checkingForBreakingVersion) return
    this.checkingForBreakingVersion = fetch("/breaking-version")
      .then(this.updateBreakingVersion.bind(this))
      .finally(this.clearCheckForBreakingVersion)
  }

  updateBreakingVersion = (response: Response) => {
    response
      .text()
      .then(this.handleBreakingVersion.bind(this))
      .catch(console.error)
      .finally(this.clearCheckForBreakingVersion)
  }

  handleBreakingVersion = (version = "") => {
    // Guard: don't cause refresh loops if these values are empty
    if (!(version && BreakingVersionDetector.BREAKING_VERSION)) return

    // Ensure the version string from the server does not include a trailing newline.
    const cleanVersion = version.trim()

    // Guard: don't cause refresh loops if breaking version is a shasum of emptystring
    if (
      cleanVersion === SHASUM_EMPTY_STRING ||
      BreakingVersionDetector.BREAKING_VERSION === SHASUM_EMPTY_STRING
    )
      return

    // Primary case: if we don't have the same version as the current breaking
    // version, time to cause a page reload.
    if (cleanVersion !== BreakingVersionDetector.BREAKING_VERSION) {
      TrackJS?.track("Breaking Version Detected")
      this.onPageRefreshRequired()
    }
  }

  clearCheckForBreakingVersion = () => {
    this.checkingForBreakingVersion = undefined
  }
}
