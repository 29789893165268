import Permissions, { SessionPermissions } from "@digits-shared/session/Permissions"
import Session from "@digits-shared/session/Session"
import SessionUser from "@digits-shared/session/User"
import { JWTAdminSession } from "src/admin/session/jwt"
import { SuperuserPermissionModule } from "src/admin/session/permissionModule"

/**
 * Session specifically for admin webapp.
 */
export default class AdminSession extends Session<JWTAdminSession> {
  protected perms: Permissions<SuperuserPermissionModule>
  constructor() {
    super()
    this.decodeSession()
  }

  /**
   * Decodes the JWT into its stored data.
   */
  protected decodeSessionUser(session?: JWTAdminSession) {
    // Set the user from the session
    this.user = new SessionUser((session && session.superuser) || undefined)
    this.decodeSessionUserPermissions()
  }

  /**
   * Decodes the JWT user permissions.
   */
  protected decodeSessionUserPermissions() {
    this.perms = new SessionPermissions(this.decodedUnsignedJWT?.superuser?.perms)
  }

  /**
   * Checks the superuser permissions to see if the provided permission module is present
   * and the permission module has the sensitive permission.
   */
  hasSensitivePermissions(module: SuperuserPermissionModule) {
    return this.isDigitsEmployee && this.perms.hasSensitivePermission(module)
  }
}
