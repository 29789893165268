import objectHelper from "@digits-shared/helpers/objectHelper"
import { ProgressivePromise } from "@digits-shared/helpers/promises/ProgressivePromise"

export interface CancelablePromise<T> {
  promise: Promise<T>
  cancel: () => void
  hasCanceled: () => boolean
}

export default {
  /**
   * Create a promise that is cancelable. This is helpful when creating a promise that can be
   * rejected when a component unmount.
   */
  makeCancelable: <T>(promise: PromiseLike<T>): CancelablePromise<T> => {
    let hasCanceled = false

    const wrappedPromise = new Promise<T>((resolve, reject) => {
      promise.then(
        (val) => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
        (error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
      )
    })

    return {
      promise: wrappedPromise,
      cancel() {
        hasCanceled = true
      },
      hasCanceled: () => hasCanceled,
    }
  },

  /**
   * Create a XHR promise that is cancelable.
   */
  makeRequest<T = unknown>(url: string, payload?: FormData, headers: Record<string, string> = {}) {
    // Create the XHR request
    const request = new XMLHttpRequest()
    request.responseType = "json"

    return new ProgressivePromise<T>(
      (resolve, reject) => {
        // Setup our listener to process completed requests
        request.onreadystatechange = () => {
          if (request.readyState !== 4) return

          if (request.status >= 200 && request.status < 300) {
            const response = objectHelper.keysToCamel(request.response as T)
            resolve(response)
          } else {
            reject({
              status: request.status,
              statusText: request.statusText,
            })
          }
        }

        const method = payload === undefined ? "GET" : "POST"
        request.open(method, url, true)
        Object.entries(headers).forEach(([headerName, headerValue]) =>
          request.setRequestHeader(headerName, headerValue)
        )

        request.send(payload)
      },
      (progress) => {
        request.upload.onprogress = (e) => {
          progress({ current: e.loaded, total: e.total, completed: e.total === e.loaded })
        }
      }
    )
  },

  makeResolved<T = void>(arg: T) {
    return new Promise<T>((resolve, reject) => {
      resolve(arg)
    })
  },

  makeRejected<T>(errorMessage: string) {
    return new Promise<T>((resolve, reject) => {
      reject(new Error(errorMessage))
    })
  },

  makeStream<T>(stream: ReadableStream<T>) {
    const reader = stream.getReader()

    const chunks: T[] = []
    return reader.read().then(function process(result: ReadableStreamReadResult<T>): Promise<T[]> {
      const { done, value } = result
      // Result objects contain two properties:
      // done  - true if the stream has already given you all its data.
      // value - some data. Always undefined when done is true.
      if (done) {
        return Promise.resolve(chunks)
      }

      chunks.push(value)

      // Read some more, and call this function again
      return reader.read().then(process)
    })
  },
}
