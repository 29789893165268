import { ApolloError, isApolloError } from "@apollo/client"
import { GRPCErrorCode } from "@digits-shared/grpc/codes"
import envHelper from "@digits-shared/helpers/envHelper"
import { GraphQLFormattedError } from "graphql"

export default {
  DEFAULT_ERROR_MESSAGE: "Oops! Need help? Contact support@digits.com",

  firstErrorCode(error?: ApolloError) {
    return error?.graphQLErrors?.[0]?.extensions?.code
  },

  /**
   * @param error {ApolloError | undefined} An ApolloError from a GraphQL request, allowed to be undefined
   * @return {boolean}
   */
  isPermissionDenied(error?: ApolloError): boolean {
    return this.firstErrorCode(error) === GRPCErrorCode.PermissionDenied
  },

  /**
   * @param error {ApolloError | undefined} An ApolloError from a GraphQL request, allowed to be undefined
   * @return {boolean}
   */
  isNotFound(error?: ApolloError): boolean {
    return this.firstErrorCode(error) === GRPCErrorCode.NotFound
  },

  /**
   * @param error {ApolloError | undefined} An ApolloError from a GraphQL request, allowed to be undefined
   * @return {boolean}
   */
  isInvalidArgument(error?: ApolloError): boolean {
    return this.firstErrorCode(error) === GRPCErrorCode.InvalidArgument
  },

  /**
   * @param error {ApolloError | undefined} An ApolloError from a GraphQL request, allowed to be undefined
   * @return {boolean}
   */
  isUnauthenticated(error?: ApolloError): boolean {
    return this.firstErrorCode(error) === GRPCErrorCode.Unauthenticated
  },

  /**
   * @param error {ApolloError | undefined} An ApolloError from a GraphQL request, allowed to be undefined
   * @return {boolean}
   */
  isUnavailable(error?: ApolloError): boolean {
    return this.firstErrorCode(error) === GRPCErrorCode.Unavailable
  },

  /**
   * @param error {ApolloError | undefined} An ApolloError from a GraphQL request, allowed to be undefined
   * @return {boolean}
   */
  isAlreadyExists(error?: ApolloError): boolean {
    return this.firstErrorCode(error) === GRPCErrorCode.AlreadyExists
  },

  /**
   * @param error {ApolloError | undefined} An ApolloError from a GraphQL request, allowed to be undefined
   * @param showRealError {boolean | undefined} Whether to show the real error. Defaults to true in Development
   * @return {string}
   */
  errorMessage(
    error?: ApolloError | Error | GraphQLFormattedError,
    showRealError?: boolean
  ): string {
    if (!error) return ""

    if (this.isGraphQLFormattedError(error) || !isApolloError(error)) {
      if (showRealError || envHelper.isDevelopment()) {
        return error.message
      }
      return this.DEFAULT_ERROR_MESSAGE
    }

    // TODO: Handle more error codes. Currently will always return default
    //  code should be enum `GRPCErrorCode`:
    switch (this.firstErrorCode(error)) {
      case GRPCErrorCode.InvalidArgument:
        return error?.graphQLErrors?.[0]?.message || "Invalid Argument"
      default:
        if (showRealError || envHelper.isDevelopment()) {
          const firstOriginalMessage = error?.graphQLErrors?.[0]?.extensions?.originalMessage
          const firstMessage = error?.graphQLErrors?.[0]?.message
          return `${showRealError === undefined && envHelper.isDevelopment() ? "[DEV] " : ""}${
            firstOriginalMessage || firstMessage || "No error message"
          }`
        }

        return this.DEFAULT_ERROR_MESSAGE
    }
  },

  emailAlreadyExistsMessage(emailAddress?: string | null, error?: ApolloError) {
    if (this.isAlreadyExists(error)) {
      return `${emailAddress || "user"} is already a member of your team.`
    }

    return this.errorMessage(error)
  },

  /**
   * @param error {ApolloError | undefined} An ApolloError from a GraphQL request, allowed to be undefined
   * @return {string}
   */
  quickBooksConnectMessage(error?: ApolloError | Error) {
    const realmIdMismatchText =
      "realm id of existing quickbooks data source does not match new credentials"
    return error && error.message.includes(realmIdMismatchText)
      ? "Digits was unable to automatically reconnect your QuickBooks account. Please contact support."
      : "We were unable to link your QuickBooks account. Please try again."
  },

  stringifyError(error?: Error | unknown) {
    // JSON.stringify deals only with enumerable properties but Error object stores the contextual data in inenumerable properties.
    // This picks the properties we want to log to debug the errors
    return JSON.stringify(error || {}, ["message", "arguments", "type", "name", "error"])
  },

  isGraphQLFormattedError(
    error: ApolloError | GraphQLFormattedError | Error | undefined
  ): error is GraphQLFormattedError {
    return !!error && (error as GraphQLFormattedError).locations !== undefined
  },
}
