export enum GRPCErrorCode {
  OK = "OK",
  Canceled = "Canceled",
  Unknown = "Unknown",
  InvalidArgument = "InvalidArgument",
  DeadlineExceeded = "DeadlineExceeded",
  NotFound = "NotFound",
  AlreadyExists = "AlreadyExists",
  PermissionDenied = "PermissionDenied",
  ResourceExhausted = "ResourceExhausted",
  FailedPrecondition = "FailedPrecondition",
  Aborted = "Aborted",
  OutOfRange = "OutOfRange",
  Unimplemented = "Unimplemented",
  Internal = "Internal",
  Unavailable = "Unavailable",
  DataLoss = "DataLoss",
  Unauthenticated = "Unauthenticated",
}
