import { ObjectIdentifier } from "@digits-graphql/frontend/graphql-bearer"

export function mergeIdentifiers(existing: ObjectIdentifier[], incoming: ObjectIdentifier[]) {
  if (!existing || !existing.length) return incoming

  const merged = existing.slice()
  const existingById = new Map<string, ObjectIdentifier>()
  merged.reduce(
    (existingMap, nextExisting) => existingMap.set(objectIdentifierId(nextExisting), nextExisting),
    existingById
  )

  incoming.forEach(
    (nextIncoming) =>
      existingById.has(objectIdentifierId(nextIncoming)) && merged.push(nextIncoming)
  )

  return merged
}

function objectIdentifierId(objectIdentifier: ObjectIdentifier) {
  return `${objectIdentifier.id}:${objectIdentifier.kind}`
}
