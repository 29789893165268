import { setContext } from "@apollo/client/link/context"
import { HttpOptions } from "@apollo/client/link/http/selectHttpOptionsAndBody"
import { authorizationBearer } from "@digits-shared/session/authorizationBearer"
import Session from "@digits-shared/session/Session"

interface Options {
  bearerGraphqlEndpoint?: string
  payGraphqlEndpoint?: string
  publicGraphqlEndpoint?: string
}

export default (session: Session, options: Options) =>
  setContext((request, previousContext) => {
    if (previousContext.publicAPI) {
      return {
        uri: options.publicGraphqlEndpoint,
        credentials: "include",
      }
    }

    const context = {
      ...previousContext,
      ...request.context,
    }

    const uri = context.payAPI ? options.payGraphqlEndpoint : options.bearerGraphqlEndpoint

    return session.bearer().then(
      (token): Partial<HttpOptions> => ({
        uri,
        headers: {
          ...authorizationBearer(token),
        },
      })
    )
  })
