import * as React from "react"
import { Operation } from "@apollo/client"
import envHelper from "@digits-shared/helpers/envHelper"
import Session from "@digits-shared/session/Session"

export const GRAPHQL_TRACE_KEY = "gql-trace"

export enum GraphQLTraceKind {
  DG_MUTATION,
  ERROR,
  SLOW,
}

export interface GraphQLTrace {
  id: string
  kind: GraphQLTraceKind
  operation: Operation
  durationMS: number
  recordedAt: number
  errorCode?: string
  errorMessage?: string
  errorPath?: (string | number)[]
}

export interface GraphQLTracer {
  state: GraphQLTraceState
  toggleDebugger: () => void
  addTrace: (trace: GraphQLTrace) => void
  clearTrace: (id: string) => void
  clearTracesByKind: (kind: GraphQLTraceKind) => void
}

interface GraphQLTraceState {
  traces: GraphQLTrace[]
  debuggerEnabled: boolean
}

const INITIAL_GRAPHQL_TRACE_STATE: GraphQLTraceState = {
  traces: [],
  debuggerEnabled: false,
}

type GraphQLTraceAction =
  | { type: "ToggleDebugger" | "EnableDebugger" | "DisableDebugger" }
  | { type: "AddTrace"; trace: GraphQLTrace }
  | { type: "ClearTrace"; id: string }
  | { type: "ClearTracesByKind"; kind: GraphQLTraceKind }

export const GraphQLTracerContext = React.createContext<GraphQLTracer>({
  state: {
    ...INITIAL_GRAPHQL_TRACE_STATE,
  },
  toggleDebugger: () => {},
  addTrace: () => {},
  clearTrace: () => {},
  clearTracesByKind: () => {},
})

export function useGraphQLTracer() {
  return React.useContext(GraphQLTracerContext)
}

export function useBuildGraphQLTracer(session: Session) {
  const [state, dispatch] = React.useReducer(graphQLTraceReducer, {
    ...INITIAL_GRAPHQL_TRACE_STATE,
    debuggerEnabled:
      session.getBooleanUserPreference(GRAPHQL_TRACE_KEY, false) && session.isDigitsEmployee,
  })

  const updateDebuggerEnabled = React.useCallback(() => {
    const debuggerEnabled =
      session.getBooleanUserPreference(GRAPHQL_TRACE_KEY, false) && session.isDigitsEmployee
    dispatch({ type: debuggerEnabled ? "EnableDebugger" : "DisableDebugger" })
  }, [session])

  // Whenever the session changes, update the debugger enabled state
  React.useEffect(() => {
    session.on(Session.CREATE_EVENT_NAMESPACE, updateDebuggerEnabled)
    session.on(Session.UPDATE_EVENT_NAMESPACE, updateDebuggerEnabled)
    session.on(Session.DELETE_EVENT_NAMESPACE, updateDebuggerEnabled)

    return () => {
      session.off(Session.CREATE_EVENT_NAMESPACE, updateDebuggerEnabled)
      session.off(Session.UPDATE_EVENT_NAMESPACE, updateDebuggerEnabled)
      session.off(Session.DELETE_EVENT_NAMESPACE, updateDebuggerEnabled)
    }
  }, [session, updateDebuggerEnabled])

  const toggleDebugger = React.useCallback(() => {
    dispatch({ type: "ToggleDebugger" })
  }, [])
  const addTrace = React.useCallback(
    (trace: GraphQLTrace) => {
      dispatch({ type: "AddTrace", trace })
      // Force enable the debugger in dev or staging, or for DG mutations
      if (
        envHelper.isDevelopment() ||
        envHelper.isStaging() ||
        (session.isDoppelganger && trace.kind === GraphQLTraceKind.DG_MUTATION)
      ) {
        dispatch({ type: "EnableDebugger" })
      }
    },
    [session.isDoppelganger]
  )
  const clearTrace = React.useCallback((id: string) => {
    dispatch({ type: "ClearTrace", id })
  }, [])
  const clearTracesByKind = React.useCallback((kind: GraphQLTraceKind) => {
    dispatch({ type: "ClearTracesByKind", kind })
  }, [])

  return React.useMemo(
    (): GraphQLTracer => ({
      state,
      toggleDebugger,
      addTrace,
      clearTrace,
      clearTracesByKind,
    }),
    [state, toggleDebugger, addTrace, clearTrace, clearTracesByKind]
  )
}

function graphQLTraceReducer(
  state: GraphQLTraceState,
  action: GraphQLTraceAction
): GraphQLTraceState {
  switch (action.type) {
    case "EnableDebugger":
      return {
        ...state,
        debuggerEnabled: true,
      }
    case "DisableDebugger":
      return {
        ...state,
        debuggerEnabled: false,
      }
    case "ToggleDebugger":
      return {
        ...state,
        debuggerEnabled: !state.debuggerEnabled,
      }
    case "AddTrace":
      return {
        ...state,
        traces: [action.trace, ...state.traces],
      }
    case "ClearTrace":
      return {
        ...state,
        traces: state.traces.filter((trace) => trace.id !== action.id),
      }
    case "ClearTracesByKind":
      return {
        ...state,
        traces: state.traces.filter((trace) => trace.kind !== action.kind),
      }
  }
}
