import React from "react"
import { Theme } from "@digits-shared/themes/theme"
import hoistStatics from "hoist-non-react-statics"
import { DefaultTheme, ThemeContext as BaseThemeContext } from "styled-components"

export const DEFAULT_THEME = Theme.Dark

export const ThemeContext = BaseThemeContext

export function useThemeContext() {
  return React.useContext(ThemeContext)
}

export function useThemeMode() {
  return useThemeContext()?.mode || DEFAULT_THEME
}

export function useIsPrintTheme() {
  return useThemeMode() === Theme.Print
}

export interface DefaultThemeProps {
  theme: DefaultTheme
}

export function withThemeContext<P>(Component: React.ComponentType<P & DefaultThemeProps>) {
  const BoundComponent = React.forwardRef<{}, React.PropsWithChildren<P>>((props: P, ref) => (
    <ThemeContext.Consumer>
      {(value: DefaultTheme) => <Component {...props} ref={ref} theme={value} />}
    </ThemeContext.Consumer>
  ))

  BoundComponent.displayName = `withThemeContext(${Component.displayName || Component.name})`

  return hoistStatics(BoundComponent, Component)
}
