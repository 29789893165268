import { Interval } from "@digits-graphql/admin/graphql-bearer"
import {
  createRoutes,
  RouteConfig,
  StaticRoutes,
} from "@digits-shared/components/Router/DigitsRoute"

class AdminStaticRoutes extends StaticRoutes {
  root = "/"
  login = "/login"
  logout = "/logout"

  userDetails = "/users/:userId"
  signUpDetails = "/signups/:signupId"
  organizationDetails = "/organizations/:organizationId"

  legalEntityDetails = "/legal-entities/:legalEntityId"

  legalEntityParties = `${this.legalEntityDetails}/parties`
  legalEntityPartiesByTypeAndDesignation = `${this.legalEntityParties}/type/:type/designation/:designation`

  legalEntityPrefixes = `${this.legalEntityDetails}/prefixes`
  legalEntityPrefixesPotential = `${this.legalEntityPrefixes}/prefixes/potential`
  legalEntityPrefixesConfirmed = `${this.legalEntityPrefixes}/prefixes/confirmed`

  legalEntitySupport = `${this.legalEntityDetails}/support`
  legalEntityValidation = `${this.legalEntitySupport}/validation`
  legalEntityEmployees = `${this.legalEntitySupport}/employees`
  legalEntityBookClosed = `${this.legalEntitySupport}/book-closed`
  legalEntityMaintenance = `${this.legalEntitySupport}/maintenance`
  legalEntityDataSourceJobErrors = `${this.legalEntitySupport}/datasourcejob/errors`

  legalEntityReports: RouteConfig = {
    parentRoute: this.legalEntitySupport,
    parameterizedPath: "/reports",
    timeParameterOptions: {
      interval: Interval.Month,
    },
  }

  legalEntityReportValidation: RouteConfig = {
    parentRoute: this.legalEntitySupport,
    parameterizedPath: "/report/validation/:reportId/:versionId",
  }

  legalEntityConnections = `${this.legalEntitySupport}/connections`
  legalEntityConnection: RouteConfig = {
    parentRoute: this.legalEntityConnections,
    parameterizedPath: "/:id",
  }

  legalEntityCategories = `${this.legalEntitySupport}/categories`
  legalEntityQBOLineClasses = `${this.legalEntitySupport}/qbo-lineclass`
  legalEntityPayrollProviders = `${this.legalEntitySupport}/payroll-providers`
  legalEntityAgentParties = `${this.legalEntitySupport}/agent-parties`
  legalEntityPartyExtractions = `${this.legalEntitySupport}/party-extractions`
  legalEntityCSVImports = `${this.legalEntitySupport}/csv-imports`
  legalEntityQBODepartments = `${this.legalEntitySupport}/qbo-departments`

  additionalServices = "/additional-services"

  legalEntities = `${this.additionalServices}/legal-entities`
  legalEntitiesByStatus = `${this.additionalServices}/legal-entities/:status`

  organizations = `${this.additionalServices}/organizations`
  priorityOrganizations = `${this.organizations}/priority`
  accountantOrganizations = `${this.organizations}/accountants`
  allOrganizations = `${this.organizations}/all`

  parties = `${this.additionalServices}/parties`
  partiesGlobal = `${this.parties}/global`
  partiesPrefixes = `${this.parties}/prefixes`
  partiesPrefixesPotential = `${this.partiesPrefixes}/potential`
  partiesPrefixesConfirmed = `${this.parties}/prefixes/confirmed`
  partiesPrefixesIgnored = `${this.parties}/prefixes/ignored`

  institutions = `${this.additionalServices}/institutions`

  doppelgangerPermits = `${this.additionalServices}/permits`

  transactions = `${this.additionalServices}/transactions`
  transactionsDetails = `${this.transactions}/:id`

  workItems = `${this.additionalServices}/work-items`
  workItemDetails = `${this.workItems}/:id`

  superusers = `${this.additionalServices}/superusers`

  design = `${this.additionalServices}/design`

  emailTemplates = `${this.additionalServices}/emailTemplates`
  emailTemplate = `${this.emailTemplates}/:template`

  chartsOfAccounts = `${this.additionalServices}/coa`
  chartsOfAccountsChart = `${this.chartsOfAccounts}/:id`

  validation = `${this.additionalServices}/validation`
  validationStats = `${this.validation}/stats`

  migrations = `${this.additionalServices}/account-migrations`
  employeeToAffiliateMigration = `${this.migrations}/employee-to-affiliate-migration`
  databaseMigrations = `${this.migrations}/database-migrations`
  databaseMigration = `${this.databaseMigrations}/:prNumber`

  localTools = `${this.additionalServices}/local-tools`
  quickbooksXmlViewer = `${this.localTools}/quickbooks-xml-viewer`

  machineLearning = `${this.additionalServices}/machine-learning`
}

export default createRoutes(AdminStaticRoutes)
