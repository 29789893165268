import * as React from "react"
import { SvgDigitsLogoNoBackground } from "@digits-shared/components/SVGIcons/digits/DigitsLogoNoBackground.svg"
import { SvgHourglass01Solid } from "@digits-shared/components/SVGIcons/solid/Hourglass01Solid.svg"
import { themedSVGIconStyles } from "@digits-shared/themes/svgIconStyles"
import styled, { css, keyframes } from "styled-components"

/*
  STYLES
*/

const FADE_IN = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const FADE_IN_OUT = keyframes`
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
`

const LogoLoadingContainer = styled.div<LogoLoadingProps>`
  position: relative;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  opacity: 0;
  animation: ${FADE_IN} 300ms ${(props) => (props.delay ? props.delay : "250ms")} ease-in-out
    forwards;
`

const SVG_STYLES = css`
  ${themedSVGIconStyles({
    light: "#32497f",
    dark: "#FFF",
  })}
  animation: ${FADE_IN_OUT} 1.4s infinite ease-in-out;
`

const SvgDigitsLogoStyled = styled(SvgDigitsLogoNoBackground)`
  ${SVG_STYLES};
`

const SvgGenericLoaderStyled = styled(SvgHourglass01Solid)`
  ${SVG_STYLES};
`

/*
  INTERFACES
*/

interface LogoLoadingProps {
  size: string
  delay?: string
}

/*
  COMPONENTS
*/
export const LogoLoading = (props: LogoLoadingProps) => (
  <LogoLoadingContainer {...props}>
    <SvgDigitsLogoStyled />
  </LogoLoadingContainer>
)

export const GenericLoading = (props: LogoLoadingProps) => (
  <LogoLoadingContainer {...props}>
    <SvgGenericLoaderStyled />
  </LogoLoadingContainer>
)
