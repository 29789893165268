import * as React from "react"
import useLazyInterval from "@digits-shared/hooks/useLazyInterval"
import useVisibilityChange from "src/shared/hooks/useVisibilityChange"

interface Props {
  frequency: number
  task: () => void
}

/**
 * Simple wrapper to perform a background task on a given frequency.
 * The background task will run only when the user is away from the browser window
 *
 * "hidden":
 *    The page content is not visible to the user. In practice this means that the document is
 *    either a background tab or part of a minimized window, or the OS screen lock is active.
 */
const BackgroundTask: React.FC<Props> = ({ frequency, task }) => {
  const [resume, timerRef] = useLazyInterval(task, frequency)

  const scheduleTask = React.useCallback(
    (visibility: DocumentVisibilityState) => {
      if (timerRef.current) clearInterval(timerRef.current)
      if (visibility === "hidden") {
        resume()
      }
    },
    [resume, timerRef]
  )

  useVisibilityChange(scheduleTask)
  return null
}
export default BackgroundTask
