import promiseHelper, { CancelablePromise } from "@digits-shared/helpers/promises/promiseHelper"

export type PromiseProgress = {
  current: number
  total: number
  completed: boolean
}

export type ProgressCallback = (value: PromiseProgress) => void

export interface IProgressivePromise<T> extends PromiseLike<T> {
  /**
   * Attaches a callback for any progress of the Promise.
   * @param onprogressed The callback to execute when the Promise has progressed.
   * @returns A Promise for the completion of which ever callback is executed.
   */
  progressed(onprogressed: ProgressCallback): IProgressivePromise<T>

  cancel: () => void
}

export class ProgressivePromise<T> implements IProgressivePromise<T> {
  private cPromise: CancelablePromise<T>

  private progressListeners: ProgressCallback[] = []

  constructor(
    executor: (resolve: (value: T) => void, reject: (reason?: {}) => void) => void,
    progressor: (progress: ProgressCallback) => void
  ) {
    progressor(this.notifyProgress)
    this.cPromise = promiseHelper.makeCancelable(new Promise<T>(executor))
  }

  then = <T1 = T, T2 = never>(
    onfulfilled?: ((value: T) => T1 | PromiseLike<T1>) | undefined | null,
    onrejected?: ((reason: {}) => T2 | PromiseLike<T2>) | undefined | null
  ) => this.cPromise.promise.then(onfulfilled, onrejected)

  progressed(onprogressed: ProgressCallback) {
    this.progressListeners.push(onprogressed)
    return this
  }

  cancel = () => this.cPromise.cancel()

  private notifyProgress = (progressValue: PromiseProgress) => {
    if (this.cPromise.hasCanceled()) return

    this.progressListeners.forEach((listener) => {
      listener(progressValue)
    })
  }
}
