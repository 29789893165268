import { TransformStream as TransformStreamPolyfill } from "web-streams-polyfill"
import { CompressionStream as CompressionStreamPonyfill } from "./compression_stream"
import { DecompressionStream as DecompressionStreamPonyfill } from "./decompression_stream"

function getGlobalValue<T>(name: string): T | undefined {
  return window?.[name as GlobalKeyType] as T | undefined
}

// Install TransformStream Polyfills if they are not supported by browser (e.g. Firefox)

const nativeTS = getGlobalValue<TransformStream>("TransformStream")
if (typeof nativeTS !== "function") {
  // eslint-disable-next-line
  // @ts-ignore
  window["TransformStream"] = TransformStreamPolyfill
}

// Install Compression Polyfills if they are not supported by browser (e.g. Safari)

const nativeCS = getGlobalValue<CompressionStreamPonyfill>("CompressionStream")
if (typeof nativeCS !== "function") {
  // eslint-disable-next-line
  // @ts-ignore
  window["CompressionStream"] = CompressionStreamPonyfill
}

const nativeDS = getGlobalValue<DecompressionStreamPonyfill>("DecompressionStream")
if (typeof nativeDS !== "function") {
  // eslint-disable-next-line
  // @ts-ignore
  window["DecompressionStream"] = DecompressionStreamPonyfill
}
