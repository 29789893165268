import { JWTObject } from "@digits-shared/session/jwt/jwtObject"

type Resources = { [key in string]: ResourceRights }

type ResourceRights = { [key in string]: number }

export interface ObjectTokenAttributes {
  id: string
  resources: Resources
}

/**
 * Simple wrapper for user stored on JWT
 *
 * Provides convenience methods, better naming
 * than what is found on the intentionally abbreviated JWT,
 * and clean types.
 */
export class ObjectToken {
  attributes: ObjectTokenAttributes

  constructor(rawToken?: JWTObject) {
    if (!rawToken) {
      this.attributes = {} as ObjectTokenAttributes
      return
    }

    const { id, res: rawResources } = rawToken

    const resources = Object.entries(rawResources).reduce((res, [resourceId, resource]) => {
      res[resourceId] = Object.entries(resource).reduce((rights, [rightId, right]) => {
        rights[rightId] = right
        return rights
      }, {} as ResourceRights)
      return res
    }, {} as Resources)

    this.attributes = {
      id,
      resources,
    }
  }

  get id() {
    return this.attributes.id
  }

  get resources() {
    return this.attributes.resources
  }
}
