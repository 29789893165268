import { SafeReadonly } from "@apollo/client/cache/core/types/common"
import { FieldFunctionOptions } from "@apollo/client/cache/inmemory/policies"
import { mergeIdentifiers } from "src/shared/initializers/typePolicies/helpers/mergeIdentifiers"
import { mergeObjectEntities } from "src/shared/initializers/typePolicies/helpers/mergeObjectEntities"

export const PENDING_ID_PREFIX = "pending_"

export const threads = {
  ThreadDetails: {
    fields: {
      tags: {
        merge: mergeIdentifiers,
      },
      commenterIds: {
        merge: mergeIdentifiers,
      },

      pending: {
        read(_: SafeReadonly<number>, { readField, canRead }: FieldFunctionOptions) {
          const id = readField<string>("id")
          return !!id && id.startsWith(PENDING_ID_PREFIX)
        },
      },
    },
  },

  ThreadWithDetails: {
    keyFields: ["thread", ["id"]],
    fields: {
      entities: {
        merge: mergeObjectEntities,
      },
    },
  },

  ThreadListWithEntities: {
    fields: {
      threads: {
        merge: false,
      },

      entities: {
        merge: mergeObjectEntities,
      },
    },
  },

  CommentWithEntities: {
    keyFields: ["comment", ["id"]],
    fields: {
      entities: {
        merge: mergeObjectEntities,
      },
    },
  },
}
