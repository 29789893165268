import { Reference } from "@apollo/client"
import { uniqueBy } from "@digits-shared/helpers/filters"

export type ExistsFn<TData = object> = (list: TData[], elem: TData) => boolean

export function reducerAppend<TData = object>(exists: ExistsFn<TData> | undefined) {
  return (list: TData[], newElem: TData) => {
    const existsInList = exists ? exists(list, newElem) : false

    if (!existsInList) list.push(newElem)
    return list
  }
}

export function reducerPrepend<TData = object>(exists: ExistsFn<TData> | undefined) {
  return (list: TData[], newElem: TData) => {
    const existsInList = exists ? exists(list, newElem) : false

    if (!existsInList) list.unshift(newElem)
    return list
  }
}

export function mergeArrays(existing: Reference[] = [], incoming: Reference[] = []) {
  // eslint-disable-next-line no-underscore-dangle
  return [...incoming, ...existing].filter(uniqueBy((r) => r.__ref))
}
