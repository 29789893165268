import { Operation } from "@apollo/client"
import { RetryLink } from "@apollo/client/link/retry"
import { buildDelayFunction } from "@apollo/client/link/retry/delayFunction"
import { buildRetryFunction } from "@apollo/client/link/retry/retryFunction"
import Session from "@digits-shared/session/Session"

/*
  Retry WS requests with backoff.

  See https://www.apollographql.com/docs/react/api/link/apollo-link-retry/ for more details.

*/
export default (session: Session) => {
  const delayFn = buildDelayFunction({
    initial: 1000,
    max: 60 * 1000,
    jitter: true,
  })

  const attemptFn = buildRetryFunction({
    max: Infinity,
    retryIf: (error, operation) => {
      // If we have an errors array, it's highly likely it's a GraphQL error, so don't retry.
      if (error && error.length) return false

      return !!session.user?.id && operation.getContext().noRetry !== true
    },
  })

  return new RetryLink({
    attempts: (count, operation, error) =>
      attemptFn(retryCount(operation, count), operation, error),
    delay: (count: number, operation, error) =>
      delayFn(retryCount(operation, count), operation, error),
  })
}

// Look for the connection attempts on the operation context. This allows for management of a count that
// can be reset if we have successfully reconnected. The count managed by `RetryLink` is absolute and
// won't reset when used in combination with a `WSLink`. Fallback to count if connection attempts is
// not present.
const retryCount = (operation: Operation, count: number): number =>
  operation.getContext().connectionAttempts || count
