import { WorkItemSupportType, WorkItemTransaction } from "@digits-graphql/frontend/graphql-bearer"

export const workItem = {
  WorkItem: {
    keyFields: ["workItemId"],

    fields: {
      transactions: {
        merge(existing: WorkItemTransaction[], incoming: WorkItemTransaction[]) {
          if (!existing?.length) return incoming

          const userProvidedTxn = existing?.find(
            (t) => t.supportType === WorkItemSupportType.UserProvided
          )

          if (!userProvidedTxn) return incoming

          return [...incoming, userProvidedTxn]
        },
      },
    },
  },
}
